import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: 'uRcBWllR9NK6ZuIdWSCUY',
      type: 'Carousel',
      props: {
        technicalName: '[DE][HP] Top slider',
        slides: [
          {
            id: '7wbyfmP8mTmjfk5MdADsk9',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3lqcdWdCdzsMsIrU4az5eX/2c9ff40731ed77e5ee492f598de1e3e7/DN_HP-Banners_2_desktop_DE.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4e9e3KHOApZE0gadybZaON/5fd06e3f285743757256954a49a52f91/DN_HP-Banners_2_mobile_DE.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'DE - iPhones x Downgrade Now',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
            },
          },
          {
            id: '5LT3ogMV1xOEgSXrfqAbdz',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/16IeLFRBTP3X0lzWWgtFJk/56d9c23e2cbdec21bf5c8e0c4f02fb34/GENERIC_HPBANNERS_iPad2021_Desktop_DE.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3NYnQDYkV0QQbmDMUFxNdu/b2999d8265955469ffdd437d59109443/GENERIC_HPBANNERS_iPad2021_Mobile_DE.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'DE - iPad x Generic',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/l/ipad/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
            },
          },
          {
            id: '1azTYTUV5JAUW4AK9pqtkL',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4pKLkgwrpNZQZS5YvI2hM5/b474283d51dc894689f7e03990610274/GENERIC_HPBANNERS_MacBookAirM1_Desktop_DE.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5TVHvVrC8a2yc2K51LqWP/e59230c8561dc8faa1e892816c8a4211/GENERIC_HPBANNERS_MacBookAirM1_Mobile_DE.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'DE - MacBook x Generic',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/l/macbook-m1-modell/28f79b52-b5c8-4b2b-8325-e61d404808f0',
            },
          },
          {
            id: 'V4ySSthiAKcDcF8bguAhI',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6oc8r1s0tfICBhoIv6Mk1x/e922d0723ba43b2eb360e9d38aed69e8/HP_Desktop.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2qiQ5sSYJXxKZnVSiZKc8u/cbea36b512351d865b9e901dbda4f2ef/HP_Mobile.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'DE- St. Pauli',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/c/playstation/ea-fc-tips-and-tricks-st-pauli-esports',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4FSfM0Xz9emobjedHh5Dc0',
      type: 'SectionBanner',
      props: {
        technicalName: '[DE][HP] Value prop',
        title: 'Deine Vorteile',
        description: 'Ob du kaufst oder verkaufst, der Planet dankt.',
      },
      hiddenDevices: [],
    },
    {
      id: '7paZfTb16vE50fzEH28ZwV',
      type: 'Reinsurance',
      props: {
        technicalName: '[DE][HP] Value prop items',
        blocks: [
          {
            id: '2xCypnahHP3l98u82xAbRN',
            title: 'Professionell erneuert',
            icon: 'IconOfficial',
            link: {
              href: 'https://backmarket.de/de-de/quality',
            },
            linkPosition: 'title',
          },
          {
            id: '4R9ZKtKNA9w4ozVWQ1hVGW',
            title: 'Für Altgeräte kassieren',
            icon: 'IconPriceUpdate',
            link: {
              href: 'https://www.backmarket.de/de-de/buyback/home',
            },
            linkPosition: 'title',
          },
          {
            id: '1JoNgwLRGBfyCBFaEPRfOr',
            title: 'Ratenzahlung',
            icon: 'IconCreditCard',
            link: {
              href: 'https://help.backmarket.com/hc/de/articles/360026643854-Welche-Zahlungsarten-gibt-es-auf-Back-Market',
            },
            linkPosition: 'title',
          },
          {
            id: '38Xf6rXsDFuas7YlLmA72a',
            title: 'App mit Extra-Features',
            icon: 'IconSmartphone',
            link: {
              href: 'https://backmarket.onelink.me/j4lA/18fgovhg',
            },
            linkPosition: 'title',
          },
        ],
        mobileScreenCols: 2.0,
        hasBackground: true,
        theme: 'white',
      },
      hiddenDevices: [],
    },
    {
      id: '6KYeyEkXRA3d6pxdZwYtWU',
      type: 'ModularCardsCarousel',
      props: {
        technicalName: '[DE][HP] Most wanted categories',
        title: 'Entdecke unsere Meistgesuchten',
        size: 'small',
        cards: [
          {
            id: '3ntWwFmn4AV6GjCD9EABCV',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '1VyEN4LjJbzaBIi1lXAzlo',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[DE][DN] iPhone',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5QiFf3IKAPnyvmJAbKAkL4/e27817e69a53f9585966df5d6af44714/Phone_HP_Category_Smartphone_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/5pOFam9DGZq71N9Mjp6oq8/02af3e695a7aa342231378ad30eb4e32/Phone_HP_Category_Smartphone_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'iPhones',
                      },
                      link: {
                        href: 'https://www.backmarket.de/de-de/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '40IxNGTzIWWEhtO9u9Y4jp',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'iPhone',
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'iPhone',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '4fsbdTFvZuBTk3MHf1pZO7',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '6RBSfKaCnOYYsdeUGU4ntG',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[DE][DN] Macbook',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5GjoGJCqWVngJRShjjG7a/09c69a84edb36fa91dcdb4f9bf3bb00a/HP_Category_Laptop_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/4W39yhNXfMespBqgSIXmLQ/ff88a939f4c4d7edfe8a8e698fa6a624/HP_Category_Laptop_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'Macbook',
                      },
                      link: {
                        href: 'https://www.backmarket.de/de-de/l/macbook/297f69c7-b41c-40dd-aa9b-93ab067eb691',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '7KJpuZ667rzWq24bjmMx4v',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'Macbook',
                      richText: {
                        nodeType: BLOCKS.DOCUMENT,
                        data: {},
                        content: [
                          {
                            nodeType: BLOCKS.HEADING_3,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: 'MacBook',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: BLOCKS.PARAGRAPH,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: '',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '2QLBavv9EYMvnFLoJwiEBS',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: 'nVnl6l1KsZ03ab3fH2grf',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[DE][DN] iPad',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/4hFoTgvuPtlS2xGUdtLsRy/4fc49e131c10fbe1522403306579a546/HP_Category_Tablet_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/2AmON8mdD9dHD2bp0521LV/ec74e276da49562369738a26d34ce87c/HP_Category_Tablet_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'iPad',
                      },
                      link: {
                        href: 'https://www.backmarket.de/de-de/l/ipad/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '2yvUTg1QN84IiZIVcQVyoH',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'iPad',
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'iPad',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '1mNqWVbKafLDZpWJzby5uJ',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '1UgyLbwzvsSg8HS8AXY0ph',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[DE][DN] Console',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5Q6Fw32r8NELQcVLLxxqim/cae458982f15fc3072decc55801cb142/Phone_HP_Category_Gaming_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/7opuBWTtcbitW7A2tcaLHx/52e5c632e3cd5d61267d62c3ba7d4d02/Phone_HP_Category_Gaming_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'Gaming console',
                      },
                      link: {
                        href: 'https://www.backmarket.de/de-de/l/konsolen/2abcb67f-ffa2-4995-b473-167490d42389',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '4xnlaAbGSGDvGI5e65FRz0',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[DE] Consoles',
                      richText: {
                        nodeType: BLOCKS.DOCUMENT,
                        data: {},
                        content: [
                          {
                            nodeType: BLOCKS.HEADING_3,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: 'Spielekonsolen',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                          {
                            nodeType: BLOCKS.PARAGRAPH,
                            data: {},
                            content: [
                              {
                                nodeType: 'text',
                                value: '',
                                marks: [],
                                data: {},
                              },
                            ],
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
        ],
        cardsHavePadding: false,
        layout: 'grid',
        mobileScreenColumns: 2,
      },
      hiddenDevices: [],
    },

    {
      id: '5XagEqzXhikuomILltpy6V',
      type: 'Carousel',
      props: {
        technicalName: '[DE][HP] Content Slider',
        slides: [
          {
            id: '1M5DP26TNcen518BCQ2AD7',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2T9ErDOpAaJ1Hi58z9pzSn/999262477ca4c1cbef9648865f431039/Slider-3-desktop-DE-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5KVuOyciLi1EKK21i1dtVq/f8c39c36df02c4b968423f30fee485d6/Slider-3-mobile-DE.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'What is Verified Refurbished',
            },
            link: {
              href: 'https://backmarket.de/de-de/quality',
            },
          },
          {
            id: 'h6iToiSZSK83XA2E2Cljp',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4gSQe7IF303sjsH71XsWM3/effdd7abff249598b9bd567a226e355e/Slider-1-desktop-noCTA-DE-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3cvd6zNtIUXNXwW5gb0xqs/90662485bc240fb0da0e32b14a394280/Slider-2-mobile-DE-noCTA.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'Refurbished, better than used',
            },
          },
          {
            id: '6yY0cwRICRlhTCD6CVwlTy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5XoWhUKhH4w8CoeR6VrKif/dc783bc71f4a0ba71bd50510061fbd2c/Slider-2-desktop-DE-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3SmRsumTmHSbe9QFD75EZL/c11c8ce2855e02965dabce38eab7a566/Slider-2-mobile-DE.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'Back Market is better than new',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/c/news/used-vs-refurbished-smartphone',
            },
          },
          {
            id: '72SGMBjkoCmLjChWWuKvha',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/75ngmt4iPow0sUYPUCdP7b/a6fe9ce6ba21a4362a1045b0e77e93ec/Slider-4-desktop-DE-CTA2-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4iilzbyxiYc1sXWAvg1BwT/6ab2a1b772101fae7c5ef0c2915762da/Slider-4-mobile-DE-CTA2.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: "We've prevented over 1 milion tons of CO2 emissions",
            },
            link: {
              href: 'https://www.backmarket.de/de-de/c/news/one-million',
            },
          },
          {
            id: '4W2Vw8tZajsecXoqXV9qvR',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5YDv2y0xq8QaRIp3v6iXpD/b7e3bf81038fe4a52545e9fc1ff5ffb6/Slider-5-desktop-DE-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4rQLaohmk9wFHk6ZfdAU9q/38320f5c0cc4f8861c8f86515a52f9da/Slider-5-mobile-DE.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: '',
            },
          },
        ],
        isFullWidth: false,
      },
      hiddenDevices: [],
    },
    {
      id: '2RpbWAM6wUKwgpb11M3d4k',
      type: 'SectionBanner',
      props: {
        technicalName: '[DE][HP] Sustainability value prop',
        title: 'Erneuerte Technik für den Planeten.',
        description:
          'Mehr aus dem machen, was schon da ist. Wir glauben daran.',
      },
      hiddenDevices: [],
    },
    {
      id: '5pomEOGSEnT36MYPxtxHvX',
      type: 'Reviews',
      props: {
        technicalName: '[DE][HP] Reviews',
        title: 'Über 15 Millionen Kunden weltweit',
        reviews_ids: [
          'abfc7620-aac9-49f2-8fe8-b21566f8e71c',
          '72ec76f2-c7ff-4398-a21d-6e3b50dbdc4e',
          '37f83791-4cc2-4e28-8896-e0d0cb576bbc',
          'f7326299-4aec-47e1-a9f7-62f94c9ad36f',
          '469e4901-ed1a-4612-859f-5715b3101edf',
          '3b398459-0bcb-464b-9719-9ba0cd0574c2',
          '469e4901-ed1a-4612-859f-5715b3101edf',
          'bc286050-d6d5-49b9-83f9-6b6d288437eb',
          'fd0ed4f2-2145-4b1f-81d9-ad022597db89',
          '61093589-6dc8-44f3-8639-060335a9ecb8',
          '2b654212-7a3e-4952-80cf-748fa80eab67',
          '77771c1a-0a71-4b6d-9960-6701f013fdc6',
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4Rzp8ABzUedU7T1ZCqqDt6',
      type: 'Media',
      props: {
        technicalName: '[DE][HP] Trade-in',
        image: {
          src: 'https://images.ctfassets.net/mmeshd7gafk1/3DDzO9xDvFZ1GVyoHjpNwz/439ca18432ed46a3f3715fa875b36d4f/Desktop___DE.png',
          width: 2244,
          height: 648,
          srcMobile:
            'https://images.ctfassets.net/mmeshd7gafk1/4JfaBEO8f6W2oboc4ShUBc/98ccf110cdb25be307c2b4f5f0b592c7/Mweb___DE.png',
          widthMobile: 780,
          heightMobile: 872,
          alt: 'trade-in',
        },
        link: {
          href: 'https://www.backmarket.de/de-de/buyback/home',
        },
      },
      hiddenDevices: [],
    },
    {
      id: '2joqQwew1A6gVvxMt4wAGd',
      type: 'ArticleCardsList',
      props: {
        technicalName: '[DE][HP] Sustainability Articles',
        title: 'Erneuerte Technik schont den Planeten',
        subtitle: '',
        articles: [
          {
            id: 'RxKOErzL2V27QnBrUjeCj',
            text: 'Verhindert Back Market wirklich Umweltschäden? Allerdings, und jeder Einkauf zahlt darauf ein.',
            category: 'news',
            translatedCategory: 'Sonstiges',
            title:
              'Back Market hat bis heute schon 1 Million Tonnen CO2e eingespart.',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5c3dea6eb352653078b99687f21010d6-meta-526-276/fa8be8e65c07d8eefc8c860da69fe716/DE-Blog_Art._Header_V2.png',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Back Market hat bis heute schon 1 Million Tonnen CO2e eingespart.',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/c/news/one-million',
            },
          },
          {
            id: '1k2AiuWMTV81DbexolawSd',
            text: 'Wenn du wiederaufbereitete Technik kaufst, ist das nicht nur gut für deinen Geldbeutel. Inzwischen wurde wissenschaftlich bewiesen, dass erneuerte Elektronik besser für den Planeten ist.',
            category: 'news',
            translatedCategory: 'Sonstiges',
            title: 'Welche Auswirkungen haben erneuerte Geräte auf die Umwelt?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/04f642b8c3886a00772983e87902d051-meta-526-276/85b172713d8621b78fd83df80f134bfb/banner_Umweltauswirkungen_blog.png',
              width: 516,
              height: 328,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Welche Auswirkungen haben erneuerte Geräte auf die Umwelt?',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/c/news/impact-of-refurbished-on-environment',
            },
          },
          {
            id: '4zXpVNNUfacmSJ2v2MHI5L',
            text: 'Du fragst dich, welche Umweltauswirkungen der Kauf eines Neugerät hat? Dann haben wir hier alle Information für dich gesammelt.',
            category: 'news',
            translatedCategory: 'Sonstiges',
            title:
              'Neu vs. Refurbished: die Umweltauswirkungen der Technologieindustrie.',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5a78d1eb313c7d4ac1c40706a7f114fa-meta-526-276/b5e148f02fc5f71bf701107e5aaa0120/DE-AT.jpg',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Neu vs. Refurbished: die Umweltauswirkungen der Technologieindustrie.',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/c/news/used-vs-refurbished-smartphone',
            },
          },
          {
            id: '2IBZEJwDPIcfhadhKW3tQt',
            text: 'Back Market ist als B Corporation zertifiziert worden. Wofür steht B Corp, und was heißt das für Back Market?',
            category: 'news',
            translatedCategory: 'Sonstiges',
            title: 'Back Market erhält B-Corp-Status',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/e636bd2a2a5edd499858d1a83be9a537-meta-526-276/2772ce425bbaffae5e19429486b45f1b/BCorp_BlogArticle_V1.png',
              width: 516,
              height: 328,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Back Market erhält B-Corp-Status',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/c/news/bcorp-status',
            },
          },
          {
            id: '3FR0yUtW5ssEdVWNgI6J1V',
            text: 'Wir verbrauchen und entsorgen unsere elektronischen Geräte in einem Tempo, mit dem wir nicht Schritt halten können. Beispielsweise werden in Frankreich nur 45 % des Elektronikschrotts gesammelt, während weltweit nur 17,4 % recycelt werden.',
            category: 'events',
            translatedCategory: 'Events',
            title: 'Was sind die Auswirkungen von Elektroschrott?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/f92779e31b42f329452bacd522e73aed-meta-526-276/8dad5e074601cd297bab31016fe2950a/E-waste-2.jpg',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Was sind die Auswirkungen von Elektroschrott?',
            },
            link: {
              href: 'https://www.backmarket.de/de-de/c/events/the-impact-of-e-waste',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '4MCbnYul3Ncu8R8i5XXf59',
      type: 'Faq',
      props: {
        technicalName: '[DE][HP] FAQ about services',
        title: '3 Fragen, die uns immer wieder gestellt werden',
        subtitle: '',
        questions: [
          {
            id: '3hwwCFZ7S8MYT7csK6Lwkm',
            title:
              'Könnt ihr mir helfen, mein altes Handy und Co. zu recyceln?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'Sogar mehr als das. ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.de/de-de/buyback/home',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'Wir kaufen es dir ab!',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        ' Du kannst dein altes Gerät in Zahlung geben oder einfach so versilbern. In wenigen Minuten bekommst du ein Angebot von einer Werkstatt. Als kleines Bonbon für dich übernehmen wir die Versandkosten.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '7yW1hCwWh5wB4uFCUVk3Io',
            title: 'Kann ich mein Gerät in Raten bezahlen?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Ja. Ein großer Vorteil erneuerter Technik ist, dass du die gleiche Funktionalität deutlich günstiger bekommst — und um diesen Preisvorteil noch attraktiver zu machen, bieten wir ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://help.backmarket.com/hc/de/articles/360026643854-Welche-Zahlungsarten-gibt-es-auf-Back-Market',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'verschiedene Optionen zur Ratenzahlung an',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: '.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '7kxUMEnvHNJO45pbkb8gYm',
            title: 'Kann ich eine Versicherung für mein Gerät kaufen?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Wir stehen dir bei den kleinen Pannen des Lebens bei. Unsere Versicherungen decken Stürze, Flüssigkeitsschäden und mehr ab. Wir nennen sie ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.de/de-de/e/backup',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'BackUp',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        ', und du kannst sie zusammen mit deinem Gerät kaufen, wenn du zur Kasse gehst. Schließlich ist es für den Planeten besser, je länger du dein Gerät nutzen kannst.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'JSON_AND_HTML',
      },
      hiddenDevices: [],
    },
    {
      id: '1VHsHyqcRJpOqows8X3NU5',
      type: 'ModularCardsCarousel',
      props: {
        technicalName: '[DE][HP] Press carousel',
        title: 'Bekannt aus',
        size: 'small',
        cards: [
          {
            id: '6r2nGAKmGWDp2xOyCUBvI0',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '6sojITu0Svyd1eWw5wnEdU',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[DE] RTL news',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5GM1q6aGIhxUPaa5Z4OXZw/5e1c5fd7e9f9a5d960ae47a4d12e74a9/rtl_news.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: '',
                      },
                      link: {
                        href: 'https://www.rtl.de/cms/stiftung-warentest-checkt-online-shops-fuer-gebrauchte-handys-5031498.html',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '4tH1A7w7F3LrmbYndgWyoj',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '2Prkf9ut0Vvht1uta5xubi',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[DE] Inside digital',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/76vaj9WtNuWycSXlTmQVTz/7360912c61ab667c53a58741146b79d4/inside_digital.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Inside digital',
                      },
                      link: {
                        href: 'https://www.inside-digital.de/news/gebrauchtes-handy-kaufen-amazon-faellt-bei-stiftung-warentest-durch',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '5zkVIYlSGuDrpqdm9uJdgK',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '5AjW89PhXdtdv6mtRFT9df',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[DE] Business Insider',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/3Je2YoVjEPdYC8MuAd1yLp/aa25d760e11e81ea62ce7c7b6320541e/Business_Insider.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Business Insider',
                      },
                      link: {
                        href: 'https://www.businessinsider.de/insider-picks/technik/stiftung-warentest-handy-gebraucht-kaufen-shops/',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '2dM1yJ00FiIdscih4vAFZH',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '4HUqesAFXTn4ZXkC68krCB',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[DE] Stern',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/4KRniQq6UgdASltsbCw8M1/4fda108cc6d723c9a26d65e2d1f31181/Stern.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Stern',
                      },
                      link: {
                        href: 'https://www.stern.de/digital/smartphones/refurbished-smartphones-kaufen--warentest-empfiehlt-diese-anbieter-33220852.html',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
        ],
        cardsHavePadding: true,
        layout: 'carousel',
        mobileScreenColumns: 1,
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Back Market: erneuerte Technik für den Planeten',
  meta: {
    title: 'Back Market: erneuerte Technik für den Planeten',
    description:
      'Der zuverlässige Marktplatz für gebrauchte & refurbished Handys, Tablets, Laptops und mehr Elektronik. Kaufe dein nächstes Gerät günstig und sicher mit 30 Tage Rückgaberecht Extragarantie.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/329980a55d3f22ade22d3825e7a6c565/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'Erneuerte Technik für den Planeten',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
